.case-card {
  transition: all 0.8s;
  height: 20rem;
  color: white;
}
.case-card .case-txt-1 {
  position: relative;
  top: 5rem;
  transition: all 0.8s;
}
.case-card .case-txt-2 {
  opacity: 0;
  transition: all 0.8s;
}
.case-card .case-fundo {
  background-color: transparent;
  width: 100%;
  height: 100%;
  transition: all 0.8s;
}

.case-card:hover .case-txt-1 {
  top: -10rem;
}
.case-card:hover .case-txt-2 {
  opacity: 1;
}
.case-card:hover .case-fundo {
  background-color: #80008080;
}

.caixa {
  background-color: white;
  border-radius: 10px;
  max-width: 1100px;
  max-height: 160px;
  margin-bottom: 3rem;
}

@media (max-width: 1200.98px) {
  .caixa {
    max-width: 920px;
    margin-top: 4rem;
  }
}
@media (max-width: 991px) {
  .caixa {
    position: relative;
    top: 5rem;
  }
}
@media (max-width: 767px) {
  .caixa {
    max-width: 670px;
  }
}